import { Controller, FieldValues } from 'react-hook-form';
import { IPhoneField } from './types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import clsx from 'clsx';

export const DarkPhoneField = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  disabled,
  testId,
  labelClassname,
  inputClassname,
}: IPhoneField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <div
            className="w-full flex flex-col"
            data-testid={testId}
          >
            <label
              className={clsx(`text-white font-semibold mb-[6px]`, {
                [`${labelClassname}`]: labelClassname,
              })}
            >
              {label}
            </label>
            <PhoneInput
              data-testid={`${testId}-input`}
              international
              addInternationalOption={false}
              flagComponent={({ country }) => {
                return <div className="font-mono text-white text-sm">{country}</div>;
              }}
              countryOptionsOrder={['US', 'CA', '|', '...']}
              defaultCountry="US"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled || isSubmitting}
              placeholder={placeholder}
              className={clsx(
                `
                  w-full relative py-[1px] border-[1px]
                  border-gray50 rounded-lg shadow-sm !h-[41px] overflow-hidden
                  font-sans bg-white10
                  [&_input]:bg-transparent
                  [&_input]:text-sm
                  [&_input]:placeholder:text-black-6
                  [&_input]:text-white
                  [&_input]:border-0
                  [&_input]:h-full
                  [&_input]:!ring
                  [&_input]:!ring-offset-0
                  [&_input]:!ring-0
                  [&.PhoneInput--focus]:outline-none
                  [&.PhoneInput--focus]:border-grey-1 
                  [&.PhoneInput--focus]:ring-pink
                  [&.PhoneInput--focus]:shadow-none
                  [&.PhoneInput--focus]:ring-4
                  [&_.PhoneInputInput]:pl-[12px]
                  [&_.PhoneInputCountry]:pl-[14px]
                  [&_.PhoneInputCountry]:w-fit
                  [&_.PhoneInputCountry]:justify-center
                  [&_.PhoneInputCountry]:mr-0
                  [&_.PhoneInputCountryIcon]:!shadow-none
                  [&_.PhoneInputCountryIcon]:!bg-transparent
                  [&_.PhoneInputCountryIcon]:!h-auto
                  [&_.PhoneInputCountryIcon]:!w-auto
                  [&_.PhoneInputCountrySelectArrow]:!m-0
                  [&_.PhoneInputCountrySelectArrow]:!p-0
                  [&_.PhoneInputCountrySelectArrow]:!w-[20px]
                  [&_.PhoneInputCountrySelectArrow]:!h-[20px]
                  [&_.PhoneInputCountrySelectArrow]:!border-0
                  [&_.PhoneInputCountrySelectArrow]:!opacity-100
                  [&_.PhoneInputCountrySelectArrow]:!transform-none
                  [&_.PhoneInputCountrySelectArrow]:bg-[url(/icons/dropdown-arrow-white.svg)]
                  [&_.PhoneInputCountrySelectArrow]:bg-no-repeat
                  [&_.PhoneInputCountrySelectArrow]:bg-center
                  [&_.PhoneInputCountrySelectArrow]:bg-contain
                `,
                {
                  [`${inputClassname}`]: inputClassname,
                  'border-red-error': error,
                },
              )}
            />
            <div className="w-full min-h-[24px] flex items-center">
              {error && (
                <p
                  data-testid={`${testId}-error-message`}
                  className="w-full text-sm text-red-error text-right"
                >
                  {error.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
